<template>
<div uk-grid class="print-only">
	<div class="uk-margin-medium-left uk-margin-medium-top uk-width-1-3">
		<img :src="logodark">
	</div>
</div>
		
  
		<!-- TOP -->
		<div v-if="showHead" class="no-print top-wrap uk-position-relative uk-light uk-background-secondary">
			
			<!-- NAV -->
			<div class="nav" data-uk-sticky="cls-active: uk-background-secondary uk-box-shadow-large; top: 100vh; animation: uk-animation-slide-top">
				<div class="uk-container">
					<nav class="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar>
						<div class="uk-navbar-left">
							<div class="uk-navbar-item uk-padding-remove">
								<router-link to="/" class="uk-width-small" title="Logo" >
									<img :src="logo"  alt="Logo">
									<!-- &nbsp;CANNONSOFT -->
								</router-link>
							</div>
						</div>
						<div class="uk-navbar-right">
							<ul class="uk-navbar-nav uk-visible@s">
								<li class="uk-active uk-visible@m"><router-link to="/" data-uk-icon="home"></router-link></li>
								<!-- <li><router-link to="/portfolio">Portfolio</router-link></li> -->
								<li v-if="!logged"><router-link to="/login">Login</router-link></li>
								<li v-if="!logged"><router-link to="/register">register</router-link></li>
								<li v-else><router-link to="/licences">Mi Cuenta</router-link></li>

								<!-- <li><router-link to="">Partners</router-link></li> -->
								<li>
									<router-link to="/services" data-uk-icon="chevron-down">Servicios</router-link>
									<div class="uk-navbar-dropdown">
										<ul class="uk-nav uk-navbar-dropdown-nav">
											<li><router-link to="/prod/035">NOM - 035</router-link></li>
											<li><router-link to="/prod/1verificapp">Verificapp</router-link></li>
											<li><router-link to="/prod/2MiDoc">MiDoc</router-link></li>
											<li><router-link to="/prod/3MiDocDiag">Evaluaciones MiDoc</router-link></li>
											<li><router-link to="/prod/5Paperless">Paperless</router-link></li>
											<li><router-link to="/prod/Courses">Tus Cursos Online</router-link></li>
											<li><router-link to="/prod/chamanga">Tus Encuestas Online</router-link></li>
											<li><router-link to="/prod/Prospecting">Prospecting</router-link></li>
											<li><router-link to="/prod/ryg">MKT1a1</router-link></li>
											<li><router-link to="/prod/solicitud">Solicitud Online</router-link></li>
										</ul>
									</div>
								</li>
								<li ><router-link to="/cotizacion">Cotiza tu desarrollo</router-link></li>
								<li v-if="logged"><a href="#" @click="logout()" to="/register">Cerrar Sesión</a></li>
								<!-- <li><router-link to="">Testimonials</router-link></li> -->
							</ul>
							<a class="uk-navbar-toggle uk-navbar-item uk-hidden@s" data-uk-toggle data-uk-navbar-toggle-icon href="#offcanvas-nav"></a>
						</div>
					</nav>
				</div>
			</div>
			<!-- /NAV -->
			<div v-if="notHome">
				
				
				<div class="uk-cover-container uk-light uk-flex uk-flex-middle top-wrap-height">
					
					<!-- TOP CONTAINER -->
					<div class="uk-container uk-flex-auto top-container uk-position-relative uk-margin-medium-top" data-uk-parallax="y: 0,50; easing:0; opacity:0.2">
						<div class="uk-width-1-2@s" data-uk-scrollspy="cls: uk-animation-slide-right-medium; target: > *; delay: 150">
							<h6 class="uk-text-primary uk-margin-small-bottom">SERVICIOS INTEGRALES</h6>
							<h1 class="uk-margin-remove-top uk-text-primary">Automatizamos lo aburrido</h1>
							<p class="subtitle-text uk-visible@s"> <i>Desarrollamos tus ideas. Mejoramos tus procesos actuales. Automatizamos la parte aburrida de tu trabajo.</i><br>
							 Trata con una sola persona y maneja todo un equipo de especialistas seleccionados para ti de acuerdo a tus necesidades. </p>
							<!-- <router-link to="#" title="Learn More" class="uk-button uk-button-primary uk-border-pill" data-uk-scrollspy-class="uk-animation-fade">VER MÁS</router-link> -->
						</div>
					</div>
					<!-- /TOP CONTAINER -->
					<!-- TOP IMAGE -->
					<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" 
					:data-srcset="
					i640 + ' 640w, ' +
					i960 + ' 960w, ' +
					i1200 + ' 1200w, ' +
					i2000 + ' 2000w ' "
					data-sizes="100vw"
					data-src="https://picsum.photos/1200/900/?image=816" alt="" data-uk-cover data-uk-img data-uk-parallax="opacity: 1,0.1; easing:0"
					>
					<!-- /TOP IMAGE -->
				</div>
				<div class="uk-position-bottom-center uk-position-medium uk-position-z-index uk-text-center">
					<a href="#content" data-uk-scroll="duration: 500" data-uk-icon="icon: arrow-down; ratio: 2"></a>
				</div>

			</div>
		</div>
		<!-- /TOP -->

		<!-- OFFCANVAS -->
		<div id="offcanvas-nav" class="no-print" data-uk-offcanvas="flip: true; overlay: false">
			<div class="uk-offcanvas-bar uk-offcanvas-bar-animation uk-offcanvas-slide">
				<button class="uk-offcanvas-close uk-close uk-icon" type="button" data-uk-close></button>

				<div uk-toggle="#offcanvas-nav">
				
				<ul class="uk-nav uk-nav-default">
					<li class="uk-active"><router-link to="/" > <span uk-icon="home"></span> &nbsp; Home</router-link></li>
					<li v-if="!logged"><router-link to="/login">Login</router-link></li>
					<li v-if="!logged"><router-link to="/register">Register</router-link></li>
					<li v-else><router-link to="/licences">Mi Cuenta</router-link></li>
					<li ><router-link to="/cotizacion">Cotiza tu desarrollo</router-link></li>
					<li class="uk-parent">
						<router-link to="/services">Servicios</router-link>
						<ul class="uk-nav-sub">
											<li><router-link to="/prod/035">NOM - 035</router-link></li>
											<li><router-link to="/prod/1verificapp">Verificapp</router-link></li>
											<li><router-link to="/prod/2MiDoc">MiDoc</router-link></li>
											<li><router-link to="/prod/3MiDocDiag">Evaluaciones MiDoc</router-link></li>
											<li><router-link to="/prod/5Paperless">Paperless</router-link></li>
											<li><router-link to="/prod/Courses">Tus Cursos Online</router-link></li>
											<li><router-link to="/prod/chamanga">Tus Encuestas Online</router-link></li>
											<li><router-link to="/prod/Prospecting">Prospecting</router-link></li>
											<li><router-link to="/prod/ryg">MKT1a1</router-link></li>
											<li><router-link to="/prod/solicitud">Solicitud Online</router-link></li>
						</ul>
					</li>
					
					<li v-if='logged'><a href="#" @click="logout()" to="/register">Cerrar Sesión</a></li>
					
					<!-- <li class="uk-nav-header">Header</li>
					<li><router-link to="#js-options"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: table"></span> Item</router-link></li>
					<li><router-link to="#"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: thumbnails"></span> Item</router-link></li>
					<li class="uk-nav-divider"></li>
					<li><router-link to="#"><span class="uk-margin-small-right uk-icon" data-uk-icon="icon: trash"></span> Item</router-link></li> -->
				</ul>

				</div>
				<!-- <h3>Title</h3>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> -->
			</div>
		</div>
		<!-- /OFFCANVAS -->


    <!-- //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    /////////////////////ROUTER VIEW////////////////////////// -->
	<div class="uk-background-secondary ">
		<router-view/>
	</div>
  

  
		<!-- FOOTER -->
		<footer class="uk-section uk-section-secondary uk-padding-remove-bottom">
			<div class="uk-container">
				<div class="uk-grid uk-grid-large" data-uk-grid>
					<div class="uk-width-1-2@m">
						<h5>NUESTRA COMPAÑÍA</h5>
						<p class="uk-text-justify">Cannonsoft, servicios empresariales y personales, integrales para ti o para tu negocio. Resolvemos problemas, mejoramos procesos, incrementamos tu rendimiento y crecemos tus ganancias a través de aplicaciones, herramientas de software e ingreniería de procesos. ¿Quieres saber más? 
							contáctanos: <a class="uk-link-text" style="color:#903396" href="mailto:contacto@cannonsoft.dev" title="">contacto@cannonsoft.dev</a> o al teléfono <a target="_blank" href="https://wa.me/5215567559889">+52 55 6755 9889</a></p>
						<!-- <div>
							<router-link to="" class="uk-icon-button" data-uk-icon="twitter"></router-link>
							<router-link to="" class="uk-icon-button" data-uk-icon="facebook"></router-link>
							<router-link to="" class="uk-icon-button" data-uk-icon="instagram"></router-link>
						</div> -->
					</div>
					<!-- <div class="uk-width-1-6@m">
						<h5>SERVICIOS</h5>
						<ul class="uk-list">
							<li>Big Data</li>
							<li>Marketing</li>
							<li>Analytics</li>
							<li>AI Lab</li>
						</ul>
					</div>
					<div class="uk-width-1-6@m">
						<h5>OUR COMPANY</h5>
						<ul class="uk-list">
							<li>Team</li>
							<li>Work</li>
							<li>Culture</li>
							<li>Contact Us</li>
						</ul>
					</div>
					<div class="uk-width-1-6@m">
						<h5>OUR OFFICES</h5>
						<ul class="uk-list">
							<li>London</li>
							<li>Chicago</li>
							<li>Dubai</li>
							<li>Brussels</li>
						</ul>
					</div> -->
					
				</div>
			</div>
			
			<div class="uk-text-center uk-padding uk-padding-remove-horizontal no-print">
				<span class="uk-text-small uk-text-muted">© 2022 CANNONSOFT | Built with <router-link to="http://getuikit.com" title="Visit UIkit 3 site" target="_blank" data-uk-tooltip><span data-uk-icon="uikit"></span></router-link></span>
			</div>
		</footer>
		<!-- /FOOTER -->
</template>

<style>

.uk-button-primary {
	background-color: #903396 !important;
	color: white !important;
}

.uk-text-primary {
	color : #903396 !important;
}

.purple-bg {
	background-color: #903396 !important;
}




.nav {
	position: absolute;
	top: 15px;
	z-index: 99;
	left: 0;
	right: 0;
}
.uk-sticky-placeholder {
	height: 0 !important;
}
.top-wrap::before, .overlay-wrap::before {
	position: absolute;
	top:0;
	right:0;
	left: 0;
	bottom: 0;
	content: '';
	z-index: 1;
	background-color: rgba(0,0,0,0.5);
}
.top-wrap-height {
	height: 80vh;
	min-height: 460px;
	transition: height 0.25s;
}
.uk-subnav-pill > * > :first-child {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.04);
}
.top-container {
	z-index: 2;
}
.uk-logo img {
	height: 38px;
}
.subtitle-text {
	font-size: 1.2em;
	opacity: 0.7;
	font-weight: 300;
}
.logos-grid img {
	display: block;
	width: 60px;
	margin: 0 auto;
	filter:  grayscale(100%);
	transition: filter 0.25s;
}
.logos-grid img:hover {
	display: block;
	width: 60px;
	margin: 0 auto;
	filter:  grayscale(0);
}


.print-only{
  display: none;
}
@media print
{    

    .uk-sticky-placeholder{
        display: none !important;
        padding: 0%;
    }

    .no-print, .no-print *
    {
        display: none !important;
        padding: 0%;
    }
    .print-only{
      display: block;
    }

    .wheat {
      color: wheat; 
      -webkit-print-color-adjust: exact;
    }
    .keep {
      -webkit-print-color-adjust: exact;
    }
    
    #nav {
      padding: 0px;
    }
    h1 {
      padding: 0px;
    }
    h3 {
      padding: 0px;
    }
}
</style>


<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import {useRoute, useRouter} from 'vue-router'
export default {
	setup(){
		const route = useRoute()
		const notHome = computed(() => {
			if(route.path == '/'){
				return true
			} else {
				return false
			}
		}) 
		const showHead = computed(() => {
			if(route.path != '/core'){
				return true
			} else {
				return false
			}
		}) 


		const logo = require('./assets/logo.png')
		const logodark = require('./assets/logo_dark.png')
		const i640 = require('./assets/640.jpg')
		const i960 = require('./assets/960.jpg')
		const i1200 = require('./assets/1200.jpg')
		const i2000 = require('./assets/2000.jpg')

		const logged = ref(false)

        const isLogged = () => {
            if(firebase.auth().currentUser === null){
                logged.value = false
            } else {
                logged.value = true
            }
        }
	

        firebase.auth().onAuthStateChanged(function(user) {
            isLogged()
        });

		onMounted(()=>{
            isLogged()
		})

		const router = useRouter()
		const logout = ()=>{
        	firebase.auth().signOut()
			router.push('/')
		}
		
		return {
			notHome,
			logo,
			logodark,
			logged,
			isLogged,
			logout,
			router,
			showHead,
			i640,
			i960,
			i1200,
			i2000
		}

	}
}
</script>