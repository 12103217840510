<template>

				


<section id="content" class="uk-section uk-section-default">
			<div class="uk-container">
				<div class="uk-section uk-section-small uk-padding-remove-top">
					<ul class="uk-subnav uk-subnav-pill uk-flex uk-flex-center" >
						<li><a class="uk-border-pill" href="#descubre" uk-scroll>Descubre</a></li>
						<li><a class="uk-border-pill" href="#beneficios" uk-scroll>Beneficios</a></li>
						<li><a class="uk-border-pill" href="#caracteristicas" uk-scroll>Características</a></li>
					</ul>
				</div>

				
					<p id="descubre"><br></p>
					
						<div class="uk-grid uk-child-width-1-2@m uk-flex-middle" data-uk-grid  data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium">
							<div>
								<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['1']" alt="" data-uk-img max-width="300px">
							</div>
							<div data-uk-scrollspy-class="uk-animation-slide-right-medium">
								<h6 class="uk-text-primary">TI como servicio</h6>
								<h2 class="uk-margin-small-top">Tecnología simplificada para ti y para tu negocio</h2>
								<p class="subtitle-text">
									Hacemos realidad tus ideas y mejoramos tu vida y tu negocio através de la tecnología y el desarrollo de procesos. Tú hablarás con un agente que lidera un equipo de ingenieros, diseñadores, programadores y desarrolladores de negocios cuya tarea es hacer tu vida más fácil.

								</p>
								<div class="uk-grid uk-child-width-1-2@s" data-uk-grid>
									<div>
										<h4>Tu agente de Cannonsoft</h4>
										<p>Es tu contacto directo con Cannonsoft, nuestros recursos y todos los servicios que ofrecemos</p>
									</div> 
									<div>
										<h4>Servicios</h4>
										<p>Soluciones y procesos mejorados, apoyados de herraminetas y aplicaciones desarrolladas específicamente para tus necesidades. </p>
									</div>
								</div>
							</div>
						</div>
						
					<hr id="beneficios">
					<p><br></p>


						<div class="uk-grid uk-flex-middle" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium">
							<div class="uk-child-width-1-3@m">
								<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['8']" alt="" data-uk-img>
							</div>
							<div data-uk-scrollspy-class="uk-animation-slide-right-medium">
								<h6 class="uk-text-primary">BENEFICIOS</h6>
								<div class="uk-grid uk-child-width-1-3@s" data-uk-grid>
									<div>
										<h3>Sin preocuparte</h3>
										<p>Tu agente de Cannonsoft se encarga de especificar y transmitir tus ideas a nuestro equipo asignado de especialistas para desarrolladar herramientas y aplicaciones. </p>
									</div>
									<div>
										<h3>Gran rapidez y portabilidad</h3>
										<p>Nuestras herramientas son desarrolladas utilizando las mejores tecnologías que nos permiten crear software de gran velocidad y bajo consumo de recursos, son ligeras y ultra compatibles.</p>
									</div>
									<div>
										<h3>Máxima seguridad <span uk-icon="icon:lock;"></span></h3>
										<p>Mantenemos los estándares más actuales y las mejores prácticas para asegurar tu información. Utilizamos herramientas proveidas por las más grandes compañías de tecnología. Además nuestros servicios son validados por compañías internacionales de seguridad.</p>
									</div>
								</div>
								<h3 class="uk-margin-small-top">Personas y organizaciones</h3>
								<p class="subtitle-text">
									Nos especializamos en herramientas y servicios que funcionan siempre y en todo lugar. Accesibles para todos o unos cuantos, tú decides.
									Desde todos tus dispositivos y con la información más actualizada.
									Trabajar con nosotros te otorga grandes beneficios a través de soporte y herraminetas. ¡Todo un departamento de tecnologías a tu disposición!
								</p>
							</div>
						</div>

					<hr id="caracteristicas">
					<p><br></p>

						<div class="uk-grid uk-child-width-1-2@m uk-flex-middle" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-medium">
							<div>
								<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['9']" alt="" data-uk-img>
							</div>
							<div data-uk-scrollspy-class="uk-animation-slide-right-medium">
								<h6 class="uk-text-primary">CARACTERÍSTICAS</h6>
								<div class="uk-grid uk-child-width-1-2@s" data-uk-grid>
									<div>
										<h3>Un agente para ti</h3>
										<p>Tu agente es tu contacto con los recursos de Cannonsoft. A través de tu agente puedes dirigir un equipo de especialistas con facilidad</p>
									</div>
									<div>
										<h3>Información simplificada y procesable</h3>
										<p>Tu agente habla programación, diseño, ingeniería, etc. Se encarga de transmitrir tus ideas a los especialistas que las pondrán en marcha.</p>
									</div>
								</div>
								<h3 class="uk-margin-small-top">Desarrolla tus aplicaciones y páginas con nosotros </h3>
								<p class="subtitle-text">
									
									Cannonsoft te ofrece un equipo de desarrollo equivalente a tener tu propio equipo In-house.  <br>
									Considerando los siguietnes beneficios:
									<ul>
										<li>
											sin preocupoarte por mantener un equipo de desarrolladores calificados
										</li>
										<li>
											sin necesidad de administración de recursos humanos 
										</li>
										<li>
											escalado a tu medida igualmente si eres solo tú o una organización completa dependiendo de tus necesidades
										</li>
									</ul>
									 <br>
									

								</p>
							</div>
						</div>
						
				
				
				
			</div>
		</section>
		<section class="uk-section uk-section-secondary uk-section-large">
			<div class="uk-container">
				<div class="uk-grid uk-child-width-1-2@m uk-flex-middle">
					<div>
						<h6>SIMPLIFICA LAS COSAS</h6>
						<h2 class="uk-margin-small-top uk-h1">Todas tu apps y herramientas en un solo lugar</h2>
						<p class="subtitle-text">
							
							Administra todas tus aplicaciones, herramientas y servicios de Cannonsoft a través de nuestra página o directamente con tu agente

							</p>
							<div uk-grid class=" uk-margin-medium-top uk-child-width-1-2 uk-hidden@m">
								<div>
									<router-link to="/cotizacion" class="uk-button uk-button-text uk-border-pill pulse"  data-uk-icon="arrow-right"> <span> <b class="uk-text-primary">Contacta un agente ahora</b> </span> </router-link>
								</div>
								<div>
									<router-link to="/register" class="uk-button uk-button-text uk-border-pill pulse" data-uk-icon="arrow-right"> <b>Abre tu cuenta cannon</b> </router-link>
								</div>
							</div>
							<div uk-grid class="uk-child-width-1-2 uk-visible@m">
								<div>
									<router-link to="/cotizacion" class="uk-button uk-button-primary uk-border-pill" data-uk-icon="arrow-right">Contacta un agente ahora</router-link>
								</div>
								<div>
									<router-link to="/register" class="uk-button uk-button-secondary uk-border-pill" data-uk-icon="arrow-right">Abre tu cuenta cannon</router-link>
								</div>
							</div>
						
						
					</div>
					<div data-uk-scrollspy="cls: uk-animation-fade">
						<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['2']" data-uk-img alt="Image">
					</div>
				</div>
			</div>
		</section>
	
		<!-- BOTTOM -->
		<section class="uk-section uk-section-default">
			
			<div class="uk-container uk-container-xsmall uk-text-center uk-section uk-padding-remove-top">
				<h5 class="uk-text-primary">CANNONSOFT</h5>
				<h2 class="uk-margin-remove uk-h1">Servicios integrales para mejorar todos tus procesos.</h2>
			</div>
			<div class="uk-container">
				<div class="uk-grid uk-grid-large uk-child-width-1-3@m" data-uk-grid data-uk-scrollspy="target: > div; delay: 150; cls: uk-animation-slide-bottom-medium">
					<div class="uk-text-center">
						<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['3']" data-uk-img alt="Image">
						<h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">Mejora tus procesos. Incrementa tu rendimiento</h4>
						<p>Nuestros servicios son apoyados por herramientas desarrolladas con la necesidad de resolver y agilizar casi cualquier situación o proceso. Actualizarse es vital. </p>
					</div>
					<div class="uk-text-center">
						<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['4']" data-uk-img alt="Image">
						<h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">En todos tus dispositivos</h4>
						<p>Nuestras aplicaciones y herramientas se diseñan para mantener la máxima compatibilidad entre dispositivos manteniendo siempre tu seguridad en mente.</p>
					</div>
					<div class="uk-text-center">
						<img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" :data-src="images['5']" data-uk-img alt="Image">
						<h4 class="uk-margin-small-bottom uk-margin-top uk-margin-remove-adjacent">La comunicación es clave</h4>
						<p>Soporte 24/7. Estamos aquí para ti todo el día, todos los días. Nuestros servicios son integrales, no basta con entregarte el software. Tus procesos son lo principal y mejorarlos es nuestra virtud.</p>
					</div>
					
				</div>
			</div>
			<p class="uk-text-center">

			<router-link to="/cotizacion" class="uk-button uk-button-large uk-button-primary uk-border-pill"> Contacta a un agente </router-link>
		
			</p></section>
		<!-- BOTTOM -->
		<!-- LOGOS -->
		<div class="uk-section uk-section-small uk-section-muted">
			<div class="uk-container">
				<h1>Marcas que nos aman</h1>
				<div class="uk-container uk-container-small">
					<div class="uk-text-center" v-if="loading">
						<p><span uk-spinner="ratio:4"></span></p>
					</div>
					<div v-else class="uk-grid uk-child-width-1-4 uk-child-width-expand@m logos-grid" data-uk-grid data-uk-scrollspy="cls: uk-animation-scale-down; target: > div > img; delay: 100">
						<div v-for="item in partners">
							<img :src="item" alt="Image">
						</div>
					</div>
				</div>

			</div>
		</div>
		<!-- /LOGOS -->
</template>

<style scoped>

.pulse {
  display: block;
  /* height: 50px;
  width: 150px; */
  /* border-radius: 25%; */
  /* background: rgb(255, 255, 255); */
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  animation: pulse 1s infinite;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
</style>

<script>
import { ref } from '@vue/reactivity'

export default {
  name: 'Home',
  setup(){
	  const partners = ref([])
	  const images = ref({
		  	"1":require('../img/marketing-1.svg'),
		  	"8":require('../img/marketing-8.svg'),
		  	"9":require('../img/marketing-9.svg'),
		  	"2":require('../img/marketing-2.svg'),
		  	"3":require('../img/marketing-3.svg'),
		  	"4":require('../img/marketing-4.svg'),
		  	"5":require('../img/marketing-5.svg'),
	  })
	  const loading = ref(true)
       var storage = firebase.storage().ref('site')
        storage.child('partners').listAll().then( async function(res) {
            for(var k in res.items ){
                var url = await res.items[k].getDownloadURL()
                partners.value.push(url)
            }
			loading.value = false
        }.bind(this))

		return{
			partners,
			images,
			loading
		}
  }	
}
</script>
