import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },



  {
    path:'/core',
    name:'Core',
    component: () => {return import(/* webpackChunkName: "core" */ '../views/services/core/sales.vue' )}
  },
  {
    path:'/core/pay',
    name:'Core Pay',
    component: () => {return import(/* webpackChunkName: "core" */ '../views/services/core/pay.vue' )}
  },


  
  {
    path:'/login',
    name:'Login',
    component: () => {return import(/* webpackChunkName: "login" */ '../views/Login.vue' )}
  },
  {
    path:'/register',
    name:'Register',
    component: () => {return import(/* webpackChunkName: "register" */ '../views/Register.vue' )}
  },
  {
    path:'/licences',
    name:'Licences',
    component: () => {return import(/* webpackChunkName: "licences" */ '../views/Licences.vue' )}
  },



  {
    path:'/cotizacion/new',
    name:'Nueva Cotizacion',
    component: () => {return import(/* webpackChunkName: "nueva cotizacion" */ '../views/Features/cotizaciones/new.vue' )}
  },
  {
    path:'/coti',
    name:'Cotizacion',
    component: () => {return import(/* webpackChunkName: "cotizacion" */ '../views/Features/cotizaciones/cotizacion.vue' )}
  },
  {
    path:'/cotizacion',
    name:'Cotiza',
    component: () => {return import(/* webpackChunkName: "coti" */ '../views/Cotizacion.vue' )}
  },



  {
    path:'/roadmap',
    name:'Roadmap demo',
    component: () => {return import(/* webpackChunkName: "roadmap" */ '../views/Features/roadmap/roadmap.vue' )}
  },
  {
    path:'/roadmap/:id',
    name:'Roadmap',
    component: () => {return import(/* webpackChunkName: "roadmap" */ '../views/Features/roadmap/roadmap.vue' )}
  },
  {
    path:'/roadmap/editor',
    name:'Crear Roadmap',
    component: () => {return import(/* webpackChunkName: "create roadmap" */ '../views/Features/roadmap/editor.vue' )}
  },
  {
    path:'/roadmap/editor/:id',
    name:'Editar Roadmap',
    component: () => {return import(/* webpackChunkName: "editar roadmap" */ '../views/Features/roadmap/editor.vue' )}
  },



  {
    path:'/services',
    name:'Services',
    component: () => {return import(/* webpackChunkName: "services" */ '../views/Services.vue' )}
  },
  {
    path: '/prod/:id',
    name: 'Prod',
    component: function () {
      return import(/* webpackChunkName: "prod" */ '../views/Prod.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
