import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const firebaseConfig = {
    apiKey: "AIzaSyA-VFJ97Nxo9ajof3bysMbBV-BQt0Zz9n4",
    authDomain: "cannonsoft-apps.firebaseapp.com",
    databaseURL: "https://cannonsoft-apps-default-rtdb.firebaseio.com",
    projectId: "cannonsoft-apps",
    storageBucket: "cannonsoft-apps.appspot.com",
    messagingSenderId: "305358012196",
    appId: "1:305358012196:web:214b0516c064a5016c3db1",
    measurementId: "G-X003FMMM6Q"
  };
  
  
  firebase.initializeApp(firebaseConfig);
  
//   firebase.auth().signInWithEmailAndPassword('7c@mail.com','TfXX8MjDtwUZv1UUDNXMwmYlQW12')
  
  
createApp(App).use(router).mount('#app')
